<template>
  <div class="tk_check">
    <div class="btns">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="周考" name="first"></el-tab-pane>
        <el-tab-pane label="班级成绩查询" name="second"></el-tab-pane>
      </el-tabs>
      <router-view></router-view>
    </div>
  </div>
</template>
<script>
export default {
  name: "TKAddLive",
  data() {
    return {
      activeName: "first",
      loadIndex: 0,
    };
  },
  created() {
    this.skipAction(this.loadIndex);
  },
  methods: {
    handleClick(tab) {
      this.loadIndex = tab.index;
      this.skipAction(tab.index);
    },
    skipAction(index) {
      if (index == 0) {
        this.$router.push({ name: "ScoreCheck" });
      } else {
        this.$router.push({ name: "PLCheckScore" });
      }
    },
  },
};
</script>
